<template>
  <div class="page">
    <!-- <top-bar :title="'求助详情'"></top-bar> -->
    <div class="content">
      <div class="list">
        <div class="item">
          <div class="tit">添加求助时间：{{ appJqSalvation.createTime }}</div>
          <div class="detail">
            <div class="top">
              <van-tag color="#1cbbb4" size="medium" v-if="appJqSalvation.salvationStatus==0">待审核</van-tag>
              <van-tag type="primary" size="medium" v-if="appJqSalvation.salvationStatus==1||appJqSalvation.salvationStatus==2||appJqSalvation.salvationStatus==3||appJqSalvation.salvationStatus==4">已审核</van-tag>
              <van-tag type="warning" size="medium" v-if="appJqSalvation.salvationStatus==6">审核不通过</van-tag>
              <span class="address">{{ appJqSalvation.specificPlace }}</span>
            </div>
            <div class="info">
              <div class="left">
                <div class="text">
                  求助类型：{{ appJqSalvation.helpTypeName }}
                </div>
                <div class="text">
                  求助人：{{ appJqSalvation.name }}/{{ appJqSalvation.idNumber }}
                </div>
                <div class="text">
                  求助描述：{{ appJqSalvation.salvationDesc }}
                </div>
                <div class="img">
                  <van-uploader v-model="fileList" :multiple="false" :deletable="false" :show-upload="false" />
                </div>
              </div>
              <div class="btnDiv">
                <div class="btn" @click="operateFun('revoke')" v-if="appJqSalvation.salvationStatus ==0">撤销救助</div>
                <div class="btn" @click="operateFun('edit')" v-if="appJqSalvation.salvationStatus ==6&&appJqSalvation.isAgain==0">修改重发</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="appJqSalvation.salvationStatus==1||appJqSalvation.salvationStatus==2||appJqSalvation.salvationStatus==3||appJqSalvation.salvationStatus==4">
          <div class="tit">审核时间：{{ appJqSalvation.createTime }}</div>
          <div class="detail">
            <div class="top">
              <van-tag type="danger" size="medium">待救助</van-tag>
              <span class="address">{{ appJqSalvation.specificPlace }}</span>
            </div>
            <div class="info">
              <div class="left">
                <div class="text">
                  求助类型：{{ appJqSalvation.helpTypeName }}
                </div>
                <div class="text">
                  求助人：{{ appJqSalvation.name }}/{{ appJqSalvation.idNumber }}
                </div>
                <div class="text">
                  求助描述：{{ appJqSalvation.salvationDesc }}
                </div>
                <div class="img">
                  <van-uploader v-model="fileList" :multiple="false" :deletable="false" :show-upload="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="appJqSalvation.salvationStatus==3||appJqSalvation.salvationStatus==4">
          <div class="tit">
            获得救助时间：{{ appJqSalvation.salvationTime || "未知" }}
          </div>
          <div class="detail">
            <div class="top">
              <van-tag type="success" size="medium">已救助</van-tag>
              <span class="address">{{ appJqSalvation.specificPlace }}</span>
            </div>
            <div class="info">
              <div class="left">
                <div class="text">
                  求助类型：{{ appJqSalvation.helpTypeName }}
                </div>
                <div class="text">
                  求助人：{{ appJqSalvation.name }}/{{ appJqSalvation.idNumber }}
                </div>
                <div class="text">
                  求助描述：{{ appJqSalvation.salvationDesc }}
                </div>
                <div class="img">
                  <van-uploader :multiple="false" v-model="fileList" :deletable="false" :show-upload="false" />
                </div>
              </div>
              <div class="btnDiv" v-if="appJqSalvation.salvationStatus==3">
                <div class="btn" @click="operateFun('evaluate')">去评价</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="appJqSalvation.salvationStatus==4">
          <div class="tit">评价时间：{{ appJqSalvation.evaluateTime }}</div>
          <div class="detail">
            <div class="top">
              <van-tag type="warning" size="medium">已评价</van-tag>
              <span class="address">{{ appJqSalvation.specificPlace }}</span>
            </div>
            <div class="info">
              <div class="left">
                <div class="text">
                  求助类型：{{ appJqSalvation.helpTypeName }}
                </div>
                <div class="text">
                  求助人：{{ appJqSalvation.name }}/{{ appJqSalvation.idNumber }}
                </div>
                <div class="text">
                  评价：
                  <van-rate v-model="appJqSalvation.evaluate" readonly size="12" color="#fe892c" />{{ evaluateText[appJqSalvation.evaluate] }}
                </div>
                <div class="text">
                  求助描述：{{ appJqSalvation.salvationDesc }}
                </div>
                <div class="img">
                  <van-uploader :multiple="false" v-model="fileList" :deletable="false" :show-upload="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
// import { upload, getDictTree } from '@/utils/common'
import { Toast, Dialog } from "vant";
import { mapState } from "vuex";
export default {
  data() {
    return {
      appJqSalvation: {},
      fileList: [],
      evaluateText: {
        1: "十分不满意",
        2: "不满意",
        3: "一般",
        4: "比较满意",
        5: "十分满意",
      },
    };
  },
  created() {
    console.log("this.data", this.id)
    console.log("da", this.$route.query);
    if (!this.id) {
      this.id = this.$route.query.id;
    }
    this.getDataList();
  },
  beforeDestroy() { },
  methods: {
    getDataList() {
      this.$httpApp({
        url: this.$httpApp.adornUrl(`/wxapp/app/appjqsalvation/infoCheck/${this.id}`),
        method: "get",
        params: this.$httpApp.adornParams()
      }).then(({ data }) => {
        if (data.code == 0) {
          // console.log(data);
          this.appJqSalvation = data.appJqSalvation;
          if (data.appJqSalvation.fileMessages) {
            data.appJqSalvation.fileMessages.map((item) => {
              this.fileList.push({ url: item.objectUrl });
            });
          }
        }
      });
    },
    operateFun(type) {
      if (type == "revoke") {
        Dialog.confirm({
          title: "提示",
          message: "是否要撤销救助?",
        }).then(() => {
          this.$httpApp({
            url: this.$httpApp.adornUrl("/wxapp/app/appjqsalvationcheck/check"),
            method: "post",
            data: this.$httpApp.adornData({
              salvationId: this.appJqSalvation.id,
              salvationStatus: 7,
              helpType: this.appJqSalvation.helpType,
            }),
          }).then(({ data }) => {
            if (data && data.code === 0) {
              Toast.success("操作成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 500);
            } else {
              Toast.success(data.msg);
            }
          });
        }).catch(() => {
          // on cancel
        });
      }
      if (type == "evaluate") {
        this.$router.push({ name: "rescue-evaluate", query: { id: this.id } });
      }
      if (type == 'edit') {
        this.$router.push({ name: "rescue-add", query: { id: this.id } });
      }
    },
  },

  components: {
    topBar,
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #f5f5f5;
  .header {
    border-top: 1px solid #eee;
  }
  .content {
    font-size: 24px;
    background: #f5f5f5;
    padding: 30px;
    box-sizing: border-box;
    .list {
      border-left: 1px solid #ccc;
      padding-left: 20px;
      .item {
        position: relative;
        &::before {
          position: absolute;
          content: " ";
          height: 20px;
          width: 20px;
          background: #ccc;
          border-radius: 50%;
          left: -30px;
          top: 0;
        }
        .tit {
          color: #686868;
          margin-bottom: 20px;
        }
        .detail {
          background: #fff;
          padding: 30px;
          box-sizing: border-box;
          border-radius: 10px;
          margin-bottom: 20px;
          .address {
            color: #000;
            font-weight: bold;
            margin-left: 20px;
          }
          .info {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .left {
              flex: 1;
            }
            .text {
              color: #a5a5a5;
              margin-bottom: 10px;
              line-height: 30px;
            }
            .btnDiv {
              width: 150px;
              .btn {
                text-align: center;
                margin-bottom: 10px;
                width: 150px;
                height: 48px;
                line-height: 48px;
                border-radius: 24px;
                color: #fff;
                background: #387ff5;
              }
            }
          }
        }
      }
    }
  }
}
</style>
